<template>
    <div>
        <div class="filter-panel">
            <input
                    type="text"
                    class="cs-input"
                    placeholder="搜索姓名/手机号/地址"
                    style="margin-left: 0"
                    v-model="queryParams.search"
            />
            <CSSelect style="margin-right: 20px">
                <select v-model="queryParams.gender">
                    <option value="">性别不限</option>
                    <option :value="1">男</option>
                    <option :value="2">女</option>
                </select>
            </CSSelect>
            <CSSelect style="margin-right: 20px">
                <select v-model="queryParams.departmentCode">
                    <option value="">全部部门</option>
                    <option
                            v-for="department in queryParamsDepartmentList"
                            :key="department.code"
                            :value="department.code"
                    >
                        {{ department.name }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect style="margin-right: 20px">
                <select v-model="queryParams.jobLevel">
                    <option value="">全部职位</option>
                    <option
                            v-for="(name, level) in jobLevelStr"
                            :key="level"
                            :value="level"
                    >
                        {{ name }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect>
                <select v-model="queryParams.isEnable">
                    <option value="">在职状态不限</option>
                    <option :value="1">在职</option>
                    <option :value="0">离职</option>
                </select>
            </CSSelect>
            <button class="btn btn-primary ml-4" @click="getStaffList">查询</button>
        </div>
        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:thead>
                    <tr>
                        <th class="text-center">姓名</th>
                        <th class="text-center">性别</th>
                        <th class="text-center">手机号</th>
                        <th class="text-center">地址</th>
                        <th class="text-center">部门</th>
                        <th class="text-center">岗位</th>
                        <th class="text-center">照片</th>
                        <th class="text-center">门卡编号</th>
                        <th class="text-center">在职状态</th>
                        <th class="text-center">权限</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="staff in staffList" :key="staff.id">
                        <td class="text-center">{{ staff.name }}</td>
                        <td class="text-center">
                            <template v-if="[1, 2].includes(staff.gender)">
                                {{ staff.gender == 1 ? "男" : "女" }}
                            </template>
                            <template v-else>-</template>
                        </td>
                        <td class="text-center">{{ staff.phone }}</td>
                        <td class="text-center">{{ staff.address }}</td>
                        <td class="text-center">
                            {{ staff.departmentName || "-" }}
                        </td>
                        <td class="text-center">
                            {{ staff.jobTitle || "-" }}
                        </td>
                        <td class="text-center">
              <span
                      class="allow-click"
                      v-if="staff.facePhoto"
                      @click="lookStaffFaceImg(staff.id)"
              >查看</span
              >
                            <template v-else>-</template>
                        </td>
                        <td class="text-center">
                            {{ staff.cordNo || "-" }}
                        </td>
                        <td class="text-center">
                            {{
                            !staff.resignTime && !Number(staff.resignBy) ? "在职" : "离职"
                            }}
                        </td>
                        <td class="text-center">
                            <span class="allow-click" @click="queryMenuByUser(staff)" v-if="staff.resourceData != null && staff.resourceData.length >0">
                                查看
                            </span>
                            <span v-else>
                                -
                            </span>
                        </td>
                    </tr>
                </template>
            </CSTable>
        </div>

        <CSDialog
                dialog-title="权限"
                dialog-width="450px"
                dialog-visible
                :dialogVisible="staffUserFlag"
                :dialogShowConfirmBtn="false"
                dialogCancelBtnText="关闭"
                @onClose="staffUserFlag = false"
                dialog-header-class="alert-bg"
        >
            <template v-slot:dialog-content>
                <div class="dialog-content">

                    <div style="font-size: 24px;text-align: left;margin-top: 20px;margin-bottom: 20px;height:195px;overflow:auto">
                        <div v-for="(item,index) in  staffUserMenu" :key="index" style="margin-bottom: 10px;margin-left: 30px">
                           {{index+1 +'、'}} {{item.name}}
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>
        <ViewImageModal />
    </div>
</template>

<script>

    import {
        queryDepartmentStaffUrl, queryDepartmentUrl
    } from "@/requestUrl";
    import CSSelect from "@/components/common/CSSelect";
    import CSTable from "@/components/common/CSTable";
    import CSDialog from "@/components/common/CSDialog";
    import {JOB_LEVEL, ORG_LEVEL} from "@/constant";
    import OSS from "ali-oss";
    import ViewImageModal from "@/components/ViewImageModal";
    export default {
        name: "NewStaff",
        components:{
            CSSelect,
            CSTable,
            CSDialog,
            ViewImageModal
        },
        data(){
            return {
                stsTokenStorage: {},
                filterHeight:0,
                queryParams: {
                    regionCode: this.$vc.getCurrentRegion().code || "",
                    departmentCode: "",
                    jobLevel: "",
                    isOnTheJob: "",
                    search: "",
                    gender: "",
                    isEnable: "",
                    showMenu:true
                },
                staffList: [],
                jobLevelStr: {
                    [JOB_LEVEL.SUPERVISOR]: "部门主管",
                    [JOB_LEVEL.TEAM_SUPERVISOR]: "小组主管",
                    [JOB_LEVEL.STAFF]: "员工",
                    [JOB_LEVEL.TRAINEE]: "实习生",
                },
                queryParamsDepartmentList:[],
                staffUserFlag:false,
                staffUserMenu:"",
            };
        },
        created() {
            this.queryDepartmentList(1);
            this.getStaffList();
        },
        methods:{
            async lookStaffFaceImg(userId) {
                await this.droneShootPicture(
                    `${userId}/${userId}.jpg`
                );
            },
            async droneShootPicture(picture) {
                const client = await this.createOss("oss-cn-shanghai", "cosmos-faces");
                let pictureUrl = client.signatureUrl(picture);
                this.$vc.emit(this.$route.path, "viewImageModal", "view", [pictureUrl]);
            },
            async createOss(region, bucket) {
                const { oss, stsToken } = this.stsTokenStorage;
                if (stsToken && stsToken.expireTime > new Date().getTime()) {
                    return oss;
                }
                let res = await this.$fly.get("/account/staff/ossKey");
                let ossKeyData = res.data;
                const ossConfig = {
                        region,
                        accessKeyId: ossKeyData.accessKeyId,
                        accessKeySecret: ossKeyData.accessKeySecret,
                        bucket,
                        stsToken: ossKeyData.token,
                    },
                    aliOss = new OSS(ossConfig);
                this.stsTokenStorage = {
                    stsToken: ossKeyData,
                    oss: aliOss,
                };
                return aliOss;
            },
            /**
             * 查询员工列表
             */
            getStaffList() {
                this.$fly
                    .post(queryDepartmentStaffUrl, {
                        ...this.queryParams,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.staffList = res.data.total ? res.data.datas : res.data;
                    });
            },
            /**
             * 查询区域内部门
             */
            queryDepartmentList(level = ORG_LEVEL.COMPANY) {
                return this.$fly
                    .post(queryDepartmentUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        code: "",
                        name: "",
                        dutyType: "",
                        parentCode:"",
                        level,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return [];
                        }
                        this.queryParamsDepartmentList = res.data || [];
                    });
            },
            queryMenuByUser(staff){
                        this.staffUserMenu = staff.resourceData;
                        this.staffUserFlag = true;
            },
        }
    }
</script>

<style scoped>

</style>
